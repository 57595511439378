/**
 * Different possible NODE_ENV values
 */
const availableEnvironments = [
  'dev-dev',
  'dev-master',
  'stg-dev',
  'stg-master',
  `stg-previous`,
  'stg-test',
  'prd-bou',
  'prd-lon',
]

module.exports = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test',
  AVAILABLE_ENVIRONMENTS: availableEnvironments,
}
