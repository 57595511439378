// #   ---------------------------
// #   Polyfills pre compiled and added in the browser
// #   ---------------------------
// Ideally, this polyfill would only target ie browsers.
// When time is proper install a bundle analyzer to see how this can be smaller
// https://github.com/zeit/next-plugins/tree/master/packages/next-bundle-analyzer

// This file runs before React and Next.js core
// This file is loaded for all browsers
// Next.js includes a number of polyfills only for older browsers like IE11
// Make sure you don't duplicate these in this file
// https://github.com/vercel/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js

//classList polyfill
import 'classlist-polyfill/src/index'
// Ads the ability to use Array.from for a NodeList in IE11
import 'nodelist-foreach-polyfill'

// Ads the ability to submit forms dynamically
// https://stackoverflow.com/questions/51026510/react-ref-form-onsubmit-handler-not-triggered
// https://stackoverflow.com/questions/27176983/dispatchevent-in-chrome-but-not-in-ie11/49071358#49071358
import './customEventsPolyfill'

import smoothscroll from 'smoothscroll-polyfill'
import { DEVELOPMENT } from '~/config/env/environmentConstants'
smoothscroll.polyfill()

//https://gitlab.com/moongoal/js-polyfill-object.fromentries/-/blob/master/index.js
//For network reasons, this was included manually instead of using npm i
if (!Object.fromEntries) {
  Object.defineProperty(Object, 'fromEntries', {
    value(entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error(
          'Object.fromEntries() requires a single iterable argument'
        )
      }

      const o = {}

      Object.keys(entries).forEach((key) => {
        const [k, v] = entries[key]

        o[k] = v
      })

      return o
    },
  })
}

if (process.env.NODE_ENV === DEVELOPMENT) {
  require('@webcomponents/shadydom')
}
